import { StackScreenProps } from "@react-navigation/stack";
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  DocumentReference,
  getDocs,
  getFirestore,
  orderBy,
  query,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import {
  KeyboardAvoidingView,
  Pressable,
  StyleSheet,
  View,
} from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";
import { useQuery } from "react-query";
import { Feather } from "@expo/vector-icons";
import { globalStyles } from "../../../styles/global";
import { connectionStore } from "../../../stores/connection";
import { BucketListNavigationParamList } from "../navigations/bucket-list";
import {
  BucketListItem,
  BucketListItemWithId,
  BucketListWithId,
} from "../types/bucket-list";
import { appStore } from "../../../stores/app";
import { Text } from "../../../components/custom";
import { colors } from "../../../styles/colors";
import { TextInput } from "../../../components/custom/text-input";
import { useEffect, useState } from "react";
import { BucketListItemActionBottomSheet } from "../bottom-sheets/bucket-list-item-action-bottom-sheet";
import { bucketListStore } from "../stores/bucket-list";
import { BucketListItemEditBottomSheet } from "../bottom-sheets/bucket-list-item-edit-bottom-sheet";

export const BucketListViewScreen = ({
  navigation,
  route,
}: StackScreenProps<BucketListNavigationParamList, "BucketListView">) => {
  const firestore = getFirestore();
  const { bucketListId } = route.params;

  const [title, setTitle] = useState<string>("");

  const { data, refetch } = useQuery(["bucket-list-items", bucketListId], () =>
    getDocs(
      query(
        collection(
          firestore,
          `/connections/${connectionStore.selectedConnection?.id}/bucket-lists/${bucketListId}/bucket-list-items`
        ),
        orderBy("order", "asc")
      )
    ).then((snapshot) =>
      snapshot.docs.map((doc) => ({
        id: doc.id,
        ref: doc.ref as DocumentReference<BucketListItem>,
        ...(doc.data() as BucketListItem),
      }))
    )
  );
  bucketListStore.refetch = refetch;

  const [loading, setLoading] = useState<boolean>(false);
  const [bucketListItems, setBucketListItems] = useState<
    BucketListItemWithId[]
  >([]);

  useEffect(() => {
    if (data) setBucketListItems(data);
  }, [data]);

  const handleUpdateOrder = async (data: BucketListItemWithId[]) => {
    if (!bucketListItems) return;

    setBucketListItems(data);
    await Promise.all(
      data.map((item, index) => {
        const foundItem: BucketListItemWithId | undefined =
          bucketListItems.find((original) => original.id === item.id);
        if (!foundItem) return null;
        return updateDoc(foundItem.ref, {
          order: index,
        });
      })
    );
    await refetch();
  };

  const handleCreateBucketListItem = async () => {
    if (loading) return;
    try {
      setLoading(true);
      await addDoc<BucketListItem>(
        collection(
          firestore,
          `/connections/${connectionStore.selectedConnection?.id}/bucket-lists/${bucketListId}/bucket-list-items`
        ) as CollectionReference<BucketListItem>,
        {
          title,
          isDone: false,
          order: bucketListItems?.length ?? 0,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        }
      );
      await refetch();
      setTitle("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1, backgroundColor: "white" }}>
      <View style={{}}>
        <TextInput
          value={title}
          onChangeText={setTitle}
          onSubmitEditing={handleCreateBucketListItem}
          blurOnSubmit={false}
          placeholder="함께하고 싶은 것들을 알려주세요!"
          style={styles.titleInput}
        />
        <Pressable
          style={styles.addButton}
          onPress={handleCreateBucketListItem}
          disabled={loading}
        >
          <Text color="white">추가하기</Text>
        </Pressable>
      </View>
      {bucketListItems ? (
        <DraggableFlatList
          data={bucketListItems}
          keyExtractor={(item) => item.id}
          onDragEnd={({ data }) => handleUpdateOrder(data)}
          dragItemOverflow
          style={{ padding: 8.0 }}
          renderItem={({ item, drag, isActive }) => (
            <BucketListItemItem
              item={item}
              drag={drag}
              isActive={isActive}
              refetch={refetch}
            />
          )}
        />
      ) : null}

      <BucketListItemActionBottomSheet />
      <BucketListItemEditBottomSheet />
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  titleInput: {
    borderRadius: 0,
    borderWidth: 0,
    borderBottomWidth: 1,
  },
  addButton: {
    paddingVertical: 4.0,
    paddingHorizontal: 8.0,
    position: "absolute",
    top: 10.0,
    right: 8.0,
    borderRadius: 8.0,
    backgroundColor: colors.primary,
  },
});

interface BucketListItemItemProps {
  item: BucketListItemWithId;
  drag: () => void;
  isActive: boolean;
  refetch: any;
}

const BucketListItemItem = ({
  item,
  drag,
  isActive,
  refetch,
}: BucketListItemItemProps) => {
  const handleOpenActionBottomSheet = () => {
    bucketListStore.selectedBucketListItem = item;
    bucketListStore.showActionBottomSheet = true;
  };

  const handleDone = () => {
    if (item.isDone) {
      updateDoc(item.ref, { isDone: false, doneAt: null });
    } else {
      updateDoc(item.ref, { isDone: true, doneAt: Timestamp.now() });
    }
    refetch();
  };

  return (
    <View
      style={[
        itemStyles.itemContainer,
        isActive && {
          transform: [{ scale: 1.05 }],
        },
        isActive && globalStyles.shadow,
      ]}
    >
      <Pressable onPress={handleDone} disabled={isActive}>
        <View
          style={
            item.isDone ? itemStyles.checkedCheckbox : itemStyles.emptyCheckbox
          }
        >
          {item.isDone ? (
            <Feather name="check" size={16} color="white" />
          ) : null}
        </View>
      </Pressable>
      <Pressable onPress={handleOpenActionBottomSheet}>
        <Text style={{ flex: 1 }}>{item.title}</Text>
      </Pressable>
      <Pressable onLongPress={drag} disabled={isActive}>
        <Feather
          name="menu"
          size={24}
          color="#666"
          style={{ marginRight: 8.0 }}
        />
      </Pressable>
      {/* <Pressable onPress={() => handleDelete()}>
        <Feather name="x" size={24} color="#666" />
      </Pressable> */}
    </View>
  );
};

const itemStyles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8.0,
    backgroundColor: "white",
  },
  emptyCheckbox: {
    width: 20,
    height: 20,
    borderRadius: 4.0,
    borderWidth: 1,
    borderColor: colors.border,
    marginRight: 8.0,
  },
  checkedCheckbox: {
    width: 20,
    height: 20,
    borderRadius: 4.0,
    borderWidth: 1,
    borderColor: colors.primary,
    marginRight: 8.0,
    backgroundColor: colors.primary,
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    paddingVertical: 8.0,
    borderStyle: "solid",
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
  },
});
