import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Text } from "../components/custom";
import { AntDesign } from "@expo/vector-icons";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { Image } from "expo-image";
import { colors } from "../styles/colors";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import { useEffect } from "react";

export const LoginScreen = () => {
  const scale = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: -1 * scale.value,
        },
      ],
      marginBottom: 8.0,
    };
  });

  useEffect(() => {
    scale.value = withRepeat(
      withTiming(8.0, { duration: 1000 }),
      Infinity,
      true
    );
  }, []);

  const handleLogin = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          marginBottom: 32.0,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Animated.Image
          source={{
            uri: require("../assets/logo.png"),
            width: 100,
            height: 100,
          }}
          style={animatedStyles}
        />

        <Text style={styles.headerText} isBold>
          커플 다이어리,{" "}
          <Text color={colors.primary} style={styles.headerText} isBold>
            와플
          </Text>
        </Text>
      </View>

      <View style={{ width: "100%", paddingHorizontal: 32.0 }}>
        <TouchableOpacity onPress={handleLogin} style={styles.loginButton}>
          <AntDesign
            name="google"
            size={20}
            color="white"
            style={styles.loginButtonIcon}
          />
          <Text style={styles.loginButtonText}>구글 계정으로 로그인</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontSize: 20.0,
  },
  loginButton: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20.0,
    paddingVertical: 12.0,
    borderRadius: 12.0,
    backgroundColor: "#DB4437",
  },
  loginButtonIcon: {
    marginRight: 8.0,
  },
  loginButtonText: {
    fontSize: 16.0,
    color: "white",
    fontWeight: "bold",
  },
});
