import React, { useEffect, useState } from "react";
import { differenceInDays, format, startOfDay } from "date-fns";
import { Dimensions, Platform, StyleSheet, View } from "react-native";
import DatePicker from "react-native-modern-datepicker";
import { TouchableOpacity } from "react-native-gesture-handler";
import { observer } from "mobx-react";
import { Image } from "expo-image";
import * as ImagePicker from "expo-image-picker";
import { getDoc, getFirestore, Timestamp, updateDoc } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { Feather } from "@expo/vector-icons";
import { Button, Modal, Text } from "../components/custom";
import { connectionStore } from "../stores/connection";
import { colors } from "../styles/colors";
import { appStore } from "../stores/app";
import Toast from "react-native-root-toast";
import { useQuery } from "react-query";
import { Connection } from "../types/connection";
import { SafeAreaView } from "react-native-safe-area-context";

const { width, height } = Dimensions.get("window");

export const HomeScreen = observer(() => {
  const firestore = getFirestore();
  const storage = getStorage();

  const { data, isFetched } = useQuery("connection", () =>
    connectionStore.selectedConnection
      ? getDoc(connectionStore.selectedConnection.ref).then((doc) => ({
          id: doc.id,
          ref: doc.ref,
          ...(doc.data() as Connection),
        }))
      : null
  );
  const [coverImage, setCoverImage] = useState<string | null>(null);

  useEffect(() => {
    if (isFetched && data && data.coverImagePath) {
      getDownloadURL(ref(storage, data.coverImagePath)).then((url) =>
        setCoverImage(url)
      );
    }
  }, [data, isFetched]);

  const [showDateSelectModal, setShowDateSelectModal] =
    useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const handleUpdateStartDate = async () => {
    if (!data) return;
    try {
      appStore.setLoading(true);
      setShowDateSelectModal(false);
      await updateDoc(data.ref, {
        startDate: Timestamp.fromDate(startOfDay(selectedDate)),
      });
      Toast.show("처음 만난 날을 등록했어요!");
    } catch (e) {
      console.error(e);
    } finally {
      appStore.setLoading(false);
    }
  };

  const pickImage = async () => {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert("Sorry, we need camera roll permissions to make this work!");
      }
    }
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      // aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled && connectionStore.selectedConnection) {
      try {
        appStore.setLoading(true);
        const imageRef = ref(
          storage,
          `/${connectionStore.selectedConnection.id}/coverImage`
        );
        const uploadResult = await uploadString(
          imageRef,
          result.assets[0].uri,
          "data_url"
        );
        await updateDoc<Connection>(connectionStore.selectedConnection.ref, {
          coverImagePath: uploadResult.ref.fullPath,
        });
        const url = await getDownloadURL(imageRef);
        setCoverImage(url);
      } finally {
        appStore.setLoading(false);
      }
    }
  };

  return (
    <SafeAreaView>
      <Modal
        visible={showDateSelectModal}
        onClose={() => setShowDateSelectModal(false)}
      >
        <Text style={styles.ddaySelectModalTitle} isBold>
          두 분이 처음 만날 날은 언제인가요?
        </Text>

        <DatePicker
          options={{
            backgroundColor: "white",
            textHeaderColor: colors.primary,
            textDefaultColor: "#333333",
            selectedTextColor: "white",
            mainColor: colors.primary,
            textSecondaryColor: "#D6C7A1",
          }}
          current={format(new Date(), "yyyy-MM-dd")}
          selected={format(selectedDate, "yyyy-MM-dd")}
          mode="calendar"
          maximumDate={format(new Date(), "yyyy-MM-dd")}
          style={{
            borderRadius: 8.0,
            marginBottom: 12.0,
            boxShadow: "initial",
          }}
          onDateChange={(date) => {
            setSelectedDate(new Date(date));
          }}
        />

        <Button
          title="날짜 선택"
          style={{
            width: "100%",
            marginBottom: 12.0,
          }}
          textStyle={{ fontSize: 16.0 }}
          onPress={handleUpdateStartDate}
        />
        <Button
          title="닫기"
          style={{
            width: "100%",
            backgroundColor: "#eee",
          }}
          textStyle={{ fontSize: 16.0, color: "#333" }}
          onPress={() => setShowDateSelectModal(false)}
        />
      </Modal>

      <View>
        {coverImage ? (
          <>
            <Image
              source={coverImage}
              style={{ width, height: height * 0.6 }}
            />
            <TouchableOpacity
              style={{ position: "absolute", left: 16.0, bottom: 16.0 }}
              onPress={pickImage}
            >
              <Feather name="image" size={24} color="#666" />
            </TouchableOpacity>
          </>
        ) : (
          <TouchableOpacity
            style={{
              width: "100%",
              height: height * 0.6,
              backgroundColor: "#ddd",
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={pickImage}
          >
            <Feather
              name="image"
              size={36}
              color="#666"
              style={{ marginBottom: 8.0 }}
            />
            <Text color="#666">배경 이미지를 등록해보세요!</Text>
          </TouchableOpacity>
        )}
      </View>

      <TouchableOpacity
        style={styles.ddayContainer}
        onPress={() => setShowDateSelectModal(true)}
        disabled={!!data?.startDate}
      >
        {data?.startDate ? (
          <>
            <Text style={styles.ddayText}>{`${
              differenceInDays(new Date(), data.startDate.toDate()) + 1
            }일`}</Text>
            <Text>
              {format(data.startDate.toDate(), "yyyy-MM-dd")}부터 지금까지
            </Text>
          </>
        ) : (
          <Text>처음 만난 날을 설정해주세요!</Text>
        )}
      </TouchableOpacity>
    </SafeAreaView>
  );
});

const styles = StyleSheet.create({
  ddaySelectModalTitle: {
    fontSize: 20.0,
    color: "white",
    marginBottom: 16.0,
  },
  ddayContainer: {
    alignItems: "center",
    paddingVertical: 16.0,
  },
  ddayText: {
    fontSize: 20.0,
    fontWeight: "bold",
  },
  contentContainer: {
    flex: 1,
    alignItems: "center",
  },
  modalContent: {
    height: "25%",
    width: "100%",
    backgroundColor: "#25292e",
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
    position: "absolute",
    bottom: 0,
  },
  titleContainer: {
    height: "16%",
    backgroundColor: "#464C55",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: "#fff",
    fontSize: 16,
  },
  pickerContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 50,
    paddingVertical: 20,
  },
});
