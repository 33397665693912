import { ImagePickerAsset } from "expo-image-picker";
import { SwiperFlatList } from "react-native-swiper-flatlist";
import { Dimensions, Pressable, StyleSheet, View } from "react-native";
import { Image } from "expo-image";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { colors } from "../../../styles/colors";
import { useRef } from "react";
import { CustomImageAsset } from "../types/image-asset";
import { diaryWriteStore } from "../stores/diary-write";

const { width } = Dimensions.get("window");

interface ImageViewerProps {
  images: CustomImageAsset[];
  setImages: React.Dispatch<React.SetStateAction<CustomImageAsset[]>>;
}

export const ImageViewer = ({ images, setImages }: ImageViewerProps) => {
  const swiperRef = useRef<SwiperFlatList>(null);

  const handleSwapImage = () => {
    diaryWriteStore.setShowSwapImageBottomSheet(true);
  };

  const handleDeleteImage = () => {
    const currentIndex = swiperRef.current?.getCurrentIndex();
    setImages(images.filter((_, index) => index !== currentIndex));
  };

  return (
    <View style={{ flexGrow: 0, flexShrink: 0, flexBasis: "auto" }}>
      <SwiperFlatList
        ref={swiperRef}
        showPagination
        data={images}
        renderItem={({ item }: { item: CustomImageAsset }) => (
          <View style={styles.item}>
            <Image
              source={
                item.diaryPhoto ? item.diaryPhoto.url : item.imageAsset?.uri
              }
              contentFit="cover"
              style={{ flex: 1, width, height: width }}
            />
          </View>
        )}
        paginationStyleItem={{ width: 10, height: 10, marginHorizontal: 4.0 }}
        paginationActiveColor={colors.primary}
        paginationDefaultColor={"rgba(0, 0, 0, 0.5)"}
      />
      <Pressable
        style={[styles.deleteButton, { left: 16.0 }]}
        onPress={handleSwapImage}
      >
        <MaterialCommunityIcons
          name="swap-horizontal-circle-outline"
          size={36}
          color="#333"
        />
      </Pressable>
      <Pressable
        style={[styles.deleteButton, { right: 16.0 }]}
        onPress={handleDeleteImage}
      >
        <MaterialCommunityIcons
          name="delete-circle-outline"
          size={36}
          color="#333"
        />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "white" },
  item: { width, height: width, justifyContent: "center" },
  deleteButton: {
    position: "absolute",
    bottom: 16.0,
  },
});
