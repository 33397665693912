import { createStackNavigator } from "@react-navigation/stack";
import { BucketListHomeScreen } from "../screens/bucket-list-home";
import { BucketListViewScreen } from "../screens/bucket-list-view";

export type BucketListNavigationParamList = {
  BucketList: undefined;
  BucketListView: { bucketListId: string };
};

const Stack = createStackNavigator<BucketListNavigationParamList>();

export const BucketListNavigation = () => {
  return (
    <Stack.Navigator initialRouteName="BucketList">
      <Stack.Screen
        name="BucketList"
        component={BucketListHomeScreen}
        options={{
          title: "버킷리스트",
          headerTitleStyle: { fontFamily: "NotoSans-Black" },
        }}
      />
      <Stack.Screen
        name="BucketListView"
        component={BucketListViewScreen}
        options={{
          title: "버킷리스트",
          headerTitleStyle: { fontFamily: "NotoSans-Black" },
        }}
      />
    </Stack.Navigator>
  );
};
