import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigatorScreenParams, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Feather } from "@expo/vector-icons";
import { CalendarScreen } from "../screens/calendar/calendar";
import { DiaryHomeScreen } from "../services/diary/screens/diary-home";
import { HomeScreen } from "../screens/home";
import { MenuScreen } from "../screens/menu/menu";
import { colors } from "../styles/colors";
import { RootNavigationParamList } from "./main";
import { BucketListHomeScreen } from "../services/bucket-list/screens/bucket-list-home";
import {
  BucketListNavigation,
  BucketListNavigationParamList,
} from "../services/bucket-list/navigations/bucket-list";

export type TabNavigationParamList = {
  BucketList: BucketListNavigationParamList;
  Diary: undefined;
  Home: undefined;
  Calendar: undefined;
  Menu: undefined;
};

const Tab = createBottomTabNavigator<TabNavigationParamList>();

export const TabNavigation = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<RootNavigationParamList>>();

  return (
    <Tab.Navigator
      initialRouteName="Home"
      screenOptions={{
        headerShown: false,
        tabBarShowLabel: false,
        tabBarInactiveTintColor: "#ddd",
        tabBarActiveTintColor: colors.primary,
      }}
    >
      <Tab.Screen
        name="BucketList"
        component={BucketListNavigation}
        options={{
          headerShown: false,
          tabBarIcon: (props) => <TabBarIcon name="archive" props={props} />,
        }}
      />
      <Tab.Screen
        name="Diary"
        component={DiaryHomeScreen}
        options={{
          headerShown: true,
          title: "다이어리",
          headerTitleStyle: { fontFamily: "NotoSans-Black" },
          headerRight: () => (
            <TouchableOpacity
              style={{ padding: 16.0 }}
              onPress={() =>
                navigation.navigate("DiaryWrite", { diaryId: null })
              }
            >
              <Feather name="plus" size={24} color="#333" />
            </TouchableOpacity>
          ),
          tabBarIcon: (props) => <TabBarIcon name="book" props={props} />,
        }}
      />
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          tabBarIcon: (props) => <TabBarIcon name="home" props={props} />,
        }}
      />
      <Tab.Screen
        name="Calendar"
        component={CalendarScreen}
        options={{
          tabBarIcon: (props) => <TabBarIcon name="calendar" props={props} />,
        }}
      />
      <Tab.Screen
        name="Menu"
        component={MenuScreen}
        options={{
          tabBarIcon: (props) => <TabBarIcon name="grid" props={props} />,
        }}
      />
    </Tab.Navigator>
  );
};

interface TabBarIconProps {
  name: "grid" | "archive" | "home" | "calendar" | "book";
  props: {
    focused: boolean;
    color: string;
    size: number;
  };
}

const TabBarIcon = ({ name, props }: TabBarIconProps) => {
  return <Feather name={name} size={props.size} color={props.color} />;
};
