import SwiperFlatList from "react-native-swiper-flatlist";
import { Dimensions, StyleSheet, View } from "react-native";
import { Image } from "expo-image";
import { colors } from "../../../styles/colors";
import { globalStyles } from "../../../styles/global";

interface ImageSwiperProps {
  images: string[];
}

export const ImageSwiper = ({ images }: ImageSwiperProps) => {
  const { width } = Dimensions.get("window");

  return (
    <View style={globalStyles.flexNone}>
      <SwiperFlatList
        showPagination
        data={images}
        renderItem={({ item }: { item: string }) => (
          <View style={{ width, height: width, justifyContent: "center" }}>
            <Image
              source={item}
              contentFit="cover"
              style={{ flex: 1, width, height: width }}
            />
          </View>
        )}
        paginationStyleItem={{ width: 10, height: 10, marginHorizontal: 4.0 }}
        paginationActiveColor={colors.primary}
        paginationDefaultColor={"rgba(0, 0, 0, 0.5)"}
      />
    </View>
  );
};
