import BottomSheet from "@gorhom/bottom-sheet";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { View } from "react-native";
import { Portal } from "@gorhom/portal";
import { BackdropComponent } from "../../../components/backdrop";
import { Button, Text } from "../../../components/custom";
import { colors } from "../../../styles/colors";
import { TextInput } from "../../../components/custom/text-input";
import {
  addDoc,
  collection,
  getFirestore,
  Timestamp,
} from "firebase/firestore";
import { connectionStore } from "../../../stores/connection";
import { appStore } from "../../../stores/app";

interface CreatNewBucketListBottomSheetProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCreate: () => void;
}

export const CreatNewBucketListBottomSheet = ({
  open,
  setOpen,
  onCreate,
}: CreatNewBucketListBottomSheetProps) => {
  const firestore = getFirestore();
  const insets = useSafeAreaInsets();
  const ref = useRef<BottomSheet>(null);

  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    if (open) ref.current?.collapse();
    else ref.current?.close();
  }, [open]);

  const handleCreate = async () => {
    try {
      appStore.setLoading(true);
      await addDoc(
        collection(
          firestore,
          `/connections/${connectionStore.selectedConnection?.id}/bucket-lists`
        ),
        {
          title,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        }
      );
      setOpen(false);
      onCreate();
    } finally {
      appStore.setLoading(false);
    }
  };

  return (
    <Portal>
      <BottomSheet
        ref={ref}
        index={-1}
        snapPoints={[195 + insets.bottom]}
        backdropComponent={BackdropComponent}
        onClose={() => setOpen(false)}
        enablePanDownToClose
        keyboardBehavior="interactive"
        keyboardBlurBehavior="restore"
      >
        <View style={{ padding: 16.0 }}>
          <Text fontSize={16.0} isBold style={{ marginBottom: 12.0 }}>
            버킷리스트 이름을 입력하세요.
          </Text>

          <TextInput
            defaultValue={title}
            onChangeText={setTitle}
            placeholder="이름"
            style={{ marginBottom: 12.0 }}
          />

          <Button
            title="새로운 버킷리스트 만들기"
            style={{ backgroundColor: colors.primary }}
            textStyle={{ fontWeight: "bold" }}
            onPress={handleCreate}
          />
        </View>
      </BottomSheet>
    </Portal>
  );
};
