import BottomSheet from "@gorhom/bottom-sheet";
import { useEffect, useRef } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Feather } from "@expo/vector-icons";
import { Text } from "../../../components/custom";
import { BackdropComponent } from "../../../components/backdrop";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { colors } from "../../../styles/colors";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface DiaryDeleteModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
}

export const DiaryDeleteModal = ({
  open,
  setOpen,
  onDelete,
}: DiaryDeleteModalProps) => {
  const insets = useSafeAreaInsets();
  const ref = useRef<BottomSheet>(null);

  useEffect(() => {
    if (open) ref.current?.collapse();
    else ref.current?.close();
  }, [open]);

  return (
    <BottomSheet
      ref={ref}
      index={-1}
      snapPoints={[180 + insets.bottom]}
      backdropComponent={BackdropComponent}
      onClose={() => setOpen(false)}
      enablePanDownToClose
    >
      <View>
        <Text style={styles.confirmationText} isBold>
          정말로 삭제할까요?
        </Text>
        <Pressable
          style={styles.button}
          onPress={() => {
            setOpen(false);
            onDelete();
          }}
        >
          <Feather name="trash" size={24} color={colors.danger} />
          <Text style={styles.buttonText} color={colors.danger}>
            삭제하기
          </Text>
        </Pressable>
        <Pressable
          style={styles.button}
          onPress={() => {
            setOpen(false);
          }}
        >
          <Feather name="arrow-left" size={24} color="#333" />
          <Text style={styles.buttonText}>취소</Text>
        </Pressable>
      </View>
    </BottomSheet>
  );
};

const styles = StyleSheet.create({
  confirmationText: {
    fontSize: 16.0,
    paddingHorizontal: 16.0,
    paddingVertical: 8.0,
  },
  button: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 16.0,
  },
  buttonText: {
    fontSize: 16.0,
    marginLeft: 8.0,
  },
});
