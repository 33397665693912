import { StyleSheet, TextInputProps } from "react-native";
import { TextInput as RNTextInput } from "react-native-gesture-handler";
import { colors } from "../../styles/colors";

export const TextInput = ({
  style,
  placeholderTextColor = "#d0d0d0",
  ...props
}: TextInputProps) => {
  return (
    <RNTextInput
      {...props}
      style={[styles.input, style]}
      placeholderTextColor={placeholderTextColor}
    />
  );
};

const styles = StyleSheet.create({
  input: {
    width: "100%",
    paddingVertical: 12.0,
    paddingHorizontal: 16.0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colors.border,
    borderRadius: 8.0,
    fontSize: 16.0,
    fontFamily: "NotoSans-Regular",
  },
});
