import { Pressable, TextStyle } from "react-native";
import { TouchableOpacityProps } from "react-native/types";
import { colors } from "../../styles/colors";
import { Text } from "./text";

interface CustomButtonProps extends TouchableOpacityProps {
  title: string;
  textStyle?: TextStyle;
}

export const Button = ({
  style,
  textStyle,
  title,
  ...props
}: CustomButtonProps) => {
  return (
    <Pressable
      style={[
        {
          borderRadius: 8.0,
          backgroundColor: colors.primary,
          paddingVertical: 12.0,
          justifyContent: "center",
          alignItems: "center",
        },
        style,
      ]}
      {...props}
    >
      <Text style={[{ color: "white", fontSize: 16.0 }, textStyle]}>
        {title}
      </Text>
    </Pressable>
  );
};
