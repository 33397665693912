import { format, startOfDay } from "date-fns";
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDocs,
  getFirestore,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { uploadString, ref, getStorage, deleteObject } from "firebase/storage";
import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { appStore } from "../../../stores/app";
import { connectionStore } from "../../../stores/connection";
import { Diary } from "../../../types/diary";
import { DiaryPhoto } from "../../../types/photo";
import { CustomImageAsset } from "../types/image-asset";

class DiaryWriteStore {
  showSwapImageBottomSheet: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setShowSwapImageBottomSheet(show: boolean) {
    this.showSwapImageBottomSheet = show;
  }

  async createNewDiary(
    user: DocumentReference,
    title: string,
    content: string,
    selectedDate: Date,
    images: CustomImageAsset[]
  ) {
    const firestore = getFirestore();
    const storage = getStorage();
    // add new diary
    const newDiary = await addDoc<Diary>(
      collection(
        firestore,
        `/connections/${connectionStore.selectedConnection?.id}/diaries`
      ) as CollectionReference<Diary>,
      {
        user,
        title,
        content,
        date: Timestamp.fromDate(startOfDay(selectedDate)),
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      }
    );
    // add new photos
    const photos = await Promise.all(
      images
        .filter((image) => !!image.imageAsset)
        .map((image) =>
          uploadString(
            ref(
              storage,
              `/${connectionStore.selectedConnection?.id}/photos/${uuidv4()}`
            ),
            image.imageAsset?.uri ?? "",
            "data_url"
          )
        )
    );
    await Promise.all(
      photos.map((photo, index) =>
        addDoc(
          collection(
            firestore,
            `/connections/${connectionStore.selectedConnection?.id}/photos`
          ),
          {
            path: photo.ref.fullPath,
            order: index,
            parent: newDiary,
            date: Timestamp.fromDate(startOfDay(selectedDate)),
          }
        )
      )
    );

    return newDiary;
  }

  async updateDiary(
    diaryDoc: DocumentReference<Diary>,
    user: DocumentReference,
    title: string,
    content: string,
    selectedDate: Date,
    images: CustomImageAsset[]
  ) {
    const firestore = getFirestore();
    const storage = getStorage();

    await updateDoc<Diary>(diaryDoc, {
      user,
      title,
      content,
      date: Timestamp.fromDate(startOfDay(selectedDate)),
      updatedAt: Timestamp.now(),
    });

    // get original photos
    const originalPhotoDocs = await getDocs(
      query(
        collection(
          firestore,
          `/connections/${connectionStore.selectedConnection?.id}/photos`
        ),
        where("parent", "==", diaryDoc)
      )
    );
    // delete photos
    await Promise.all(
      originalPhotoDocs.docs
        .filter(
          (doc) => !images.find((image) => image.diaryPhoto?.id === doc.id)
        )
        .map((doc) => {
          return deleteObject(
            ref(storage, (doc.data() as DiaryPhoto).path)
          ).then(() => deleteDoc(doc.ref));
        })
    );

    // add or update photos
    await Promise.all(
      images.map((image, index) => {
        if (image.diaryPhoto) {
          return updateDoc(image.diaryPhoto.ref, {
            order: index,
            date: Timestamp.fromDate(startOfDay(selectedDate)),
          });
        } else if (image.imageAsset) {
          return uploadString(
            ref(
              storage,
              `/${connectionStore.selectedConnection?.id}/photos/${uuidv4()}`
            ),
            image.imageAsset.uri,
            "data_url"
          ).then((result) => {
            addDoc(
              collection(
                firestore,
                `/connections/${connectionStore.selectedConnection?.id}/photos`
              ),
              {
                path: result.ref.fullPath,
                order: index,
                parent: diaryDoc,
                date: Timestamp.fromDate(startOfDay(selectedDate)),
              }
            );
          });
        }
      })
    );

    return diaryDoc;
  }

  async deleteDiary(diaryId: string) {
    const firestore = getFirestore();
    const storage = getStorage();

    const diaryRef = doc(
      firestore,
      `/connections/${connectionStore.selectedConnection?.id}/diaries/${diaryId}`
    );
    try {
      appStore.setLoading(true);
      // delete photos
      const photoDocs = await getDocs(
        query(
          collection(
            firestore,
            `/connections/${connectionStore.selectedConnection?.id}/photos`
          ),
          where("parent", "==", diaryRef)
        )
      );
      await Promise.all(
        photoDocs.docs.map((doc) => {
          return deleteObject(
            ref(storage, (doc.data() as DiaryPhoto).path)
          ).then(() => deleteDoc(doc.ref));
        })
      );
      // delete diary
      await deleteDoc(diaryRef);
    } catch (e) {
      console.error(e);
    } finally {
      appStore.setLoading(false);
    }
  }
}

export const diaryWriteStore = new DiaryWriteStore();
