import { makeAutoObservable } from "mobx";

class AppStore {
  loading: boolean = false;
  screenReady: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setScreenReady(ready: boolean) {
    this.screenReady = ready;
  }
}

export const appStore = new AppStore();
