import { useEffect, useState } from "react";
import { View, KeyboardAvoidingView, StyleSheet, Platform } from "react-native";
import {
  ScrollView,
  TextInput,
  TouchableOpacity,
} from "react-native-gesture-handler";
import { v4 as uuidv4 } from "uuid";
import * as ImagePicker from "expo-image-picker";
import DatePicker from "react-native-modern-datepicker";
import { Feather } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { format } from "date-fns";
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Toast from "react-native-root-toast";
import { RootNavigationParamList } from "../../../navigations/main";
import { Modal, Text } from "../../../components/custom";
import { Button } from "../../../components/custom/button";
import { colors } from "../../../styles/colors";
import { connectionStore } from "../../../stores/connection";
import { appStore } from "../../../stores/app";
import { authStore } from "../../../stores/auth";
import { Diary } from "../../../types/diary";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { ImageSwapBottomSheet } from "../components/image-swap-bottom-sheet";
import { ImageViewer } from "../components/image-viewer";
import { CustomImageAsset } from "../types/image-asset";
import { diaryWriteStore } from "../stores/diary-write";
import { DiaryPhoto } from "../../../types/photo";

export const DiaryWriteScreen = ({
  navigation,
  route,
}: NativeStackScreenProps<RootNavigationParamList, "DiaryWrite">) => {
  const firestore = getFirestore();
  const storage = getStorage();
  const { params } = route;
  const insets = useSafeAreaInsets();

  const [textInputHeight, setTextInputHeight] = useState<number>(0);
  const [showDateSelectModal, setShowDateSelectModal] =
    useState<boolean>(false);
  const [tempSelectedDate, setTempSelectedDate] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [images, setImages] = useState<CustomImageAsset[]>([]);

  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");

  const pickImage = async () => {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert("Sorry, we need camera roll permissions to make this work!");
      }
    }
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      // aspect: [4, 3],
      quality: 1,
      allowsMultipleSelection: true,
    });

    if (!result.canceled) {
      setImages(
        images.concat(
          result.assets.map((item) => ({
            imageAsset: { ...item, id: uuidv4() },
          }))
        )
      );
    }
  };

  const handleNewDiary = async () => {
    if (!authStore.user) return;
    if (!title) {
      Toast.show("제목을 입력해주세요.");
      return;
    }
    if (!content) {
      Toast.show("내용을 입력해주세요.");
      return;
    }
    try {
      appStore.setLoading(true);
      if (!params.diaryId) {
        // new diary
        const newDiary = await diaryWriteStore.createNewDiary(
          authStore.user.ref,
          title,
          content,
          selectedDate,
          images
        );
        Toast.show("새로운 다이어리가 작성되었어요!");
        navigation.replace("DiaryView", { diaryId: newDiary.id });
      } else {
        // edit diary
        const updatedDiary = await diaryWriteStore.updateDiary(
          doc(
            firestore,
            `/connections/${connectionStore.selectedConnection?.id}/diaries/${params.diaryId}`
          ) as DocumentReference<Diary>,
          authStore.user.ref,
          title,
          content,
          selectedDate,
          images
        );
        Toast.show("다이어리를 수정했어요!");
        navigation.replace("DiaryView", { diaryId: updatedDiary.id });
      }
    } catch (e) {
      console.error(e);
    } finally {
      appStore.setLoading(false);
    }
  };

  const loadData = async (diaryId: string) => {
    const diaryRef = doc(
      firestore,
      `/connections/${connectionStore.selectedConnection?.id}/diaries/${diaryId}`
    );
    const diaryDoc = await getDoc(diaryRef);
    const { title, content, date } = diaryDoc.data() as Diary;
    setSelectedDate(date.toDate());
    setTitle(title);
    setContent(content);

    // get photos
    const photoDocs = await getDocs(
      query(
        collection(
          firestore,
          `/connections/${connectionStore.selectedConnection?.id}/photos`
        ),
        where("parent", "==", diaryRef),
        orderBy("order", "asc")
      )
    );
    const photoURLs = await Promise.all(
      photoDocs.docs.map((doc) =>
        getDownloadURL(ref(storage, (doc.data() as DiaryPhoto).path))
      )
    );
    setImages(
      photoURLs.map((url, index) => ({
        diaryPhoto: {
          id: photoDocs.docs[index].id,
          ref: photoDocs.docs[index].ref,
          ...(photoDocs.docs[index].data() as DiaryPhoto),
          url,
        },
      }))
    );
  };

  useEffect(() => {
    if (params?.diaryId) loadData(params.diaryId);
  }, [params]);

  return (
    <KeyboardAvoidingView
      style={[
        styles.container,
        {
          justifyContent: "space-between",
          paddingTop: insets.top,
          paddingBottom: insets.bottom,
          paddingLeft: insets.left,
          paddingRight: insets.right,
        },
      ]}
    >
      <View style={styles.headerContainer}>
        <TouchableOpacity
          containerStyle={styles.headerActionGroupButton}
          onPress={() => {
            if (params.diaryId) {
              navigation.replace("DiaryView", { diaryId: params.diaryId });
            } else {
              navigation.goBack();
            }
          }}
        >
          <Feather name="arrow-left" size={24} color="#333" />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => setShowDateSelectModal(true)}
          style={styles.dateTextContainer}
        >
          <Text style={styles.dateText}>
            {format(selectedDate, "yyyy-MM-dd")}
          </Text>
          <Feather name="chevron-down" size={24} color="#333" />
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={styles.headerActionGroupButton}
          onPress={handleNewDiary}
        >
          <Feather name="check" size={24} color="#333" />
        </TouchableOpacity>
      </View>
      <Modal
        visible={showDateSelectModal}
        onClose={() => {
          // reset Temp Selected Date
          setTempSelectedDate(selectedDate);
          setShowDateSelectModal(false);
        }}
      >
        <DatePicker
          options={{
            backgroundColor: "white",
            textHeaderColor: colors.primary,
            textDefaultColor: "#333333",
            selectedTextColor: "white",
            mainColor: colors.primary,
            textSecondaryColor: "#D6C7A1",
          }}
          current={format(new Date(), "yyyy-MM-dd")}
          selected={format(selectedDate, "yyyy-MM-dd")}
          mode="calendar"
          maximumDate={format(new Date(), "yyyy-MM-dd")}
          style={{
            borderRadius: 8.0,
            marginBottom: 12.0,
            boxShadow: "initial",
          }}
          onDateChange={(date) => {
            setTempSelectedDate(new Date(date));
          }}
        />

        <Button
          title="날짜 선택"
          style={{
            width: "100%",
          }}
          textStyle={{ fontSize: 16.0 }}
          onPress={() => {
            setSelectedDate(tempSelectedDate);
            setShowDateSelectModal(false);
          }}
        />
      </Modal>

      <ScrollView
        style={styles.container}
        contentContainerStyle={styles.scrollViewContainer}
      >
        {images.length > 0 ? (
          <ImageViewer images={images} setImages={setImages} />
        ) : null}
        <TextInput
          style={styles.titleInput}
          placeholder="다이어리의 제목을 입력하세요!"
          placeholderTextColor="#d0d0d0"
          value={title}
          onChangeText={setTitle}
        />
        <TextInput
          style={[styles.contentInput, { height: textInputHeight }]}
          multiline
          placeholder={`${format(
            selectedDate,
            "yyyy-MM-dd"
          )}, 어떤 일이 있었나요?`}
          placeholderTextColor="#d0d0d0"
          onContentSizeChange={(e) =>
            setTextInputHeight(e.nativeEvent.contentSize.height)
          }
          value={content}
          onChangeText={setContent}
        />
      </ScrollView>
      <View style={styles.bottomActionGroup}>
        <TouchableOpacity containerStyle={styles.bottomActionGroupButton}>
          <Feather name="hash" size={24} color="#333" />
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={styles.bottomActionGroupButton}
          onPress={pickImage}
        >
          <Feather name="image" size={24} color="#333" />
        </TouchableOpacity>
        <TouchableOpacity containerStyle={styles.bottomActionGroupButton}>
          <Feather name="map-pin" size={24} color="#333" />
        </TouchableOpacity>
      </View>

      <ImageSwapBottomSheet images={images} setImages={setImages} />
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    height: 64,
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderStyle: "solid",
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
  },
  headerActionGroupButton: {
    padding: 16.0,
    justifyContent: "center",
    alignItems: "center",
  },
  scrollViewContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  dateTextContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dateText: {
    fontSize: 20.0,
    fontWeight: "bold",
    marginRight: 4.0,
    color: "#333",
  },
  titleInput: {
    fontSize: 16.0,
    padding: 16.0,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    borderStyle: "solid",
  },
  contentInput: {
    fontSize: 16.0,
    padding: 16.0,
    backgroundColor: "white",
    lineHeight: 20.0,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    // overflow: "hidden",
    // minHeight: "100%",
  },
  bottomActionGroup: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: "#ddd",
    flexGrow: 0,
  },
  bottomActionGroupButton: {
    flex: 1,
    paddingVertical: 16.0,
    justifyContent: "center",
    alignItems: "center",
  },
});
