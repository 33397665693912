import { getAuth, signOut } from "firebase/auth";
import { View, Text, Button, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

export const MenuScreen = () => {
  const auth = getAuth();

  return (
    <SafeAreaView style={styles.container}>
      <Button title="로그아웃" onPress={() => signOut(auth)} />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-end",
  },
});
