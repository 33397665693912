import {
  addDoc,
  collection,
  collectionGroup,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Timestamp,
  where,
} from "firebase/firestore";
import { makeAutoObservable } from "mobx";
import {
  ConnectedUser,
  Connection,
  ConnectionWithConnectedUsers,
} from "../types/connection";
import { appStore } from "./app";
import { authStore } from "./auth";

class ConnectionStore {
  selectedConnection: ConnectionWithConnectedUsers | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async checkConnection(userRef: DocumentReference): Promise<QuerySnapshot> {
    const firestore = getFirestore();
    return getDocs(
      query(
        collectionGroup(firestore, "connected-users"),
        where("user", "==", userRef),
        limit(1)
      )
    );
  }

  async fetchConnection(userRef: DocumentReference) {
    if (!userRef) return;

    const firestore = getFirestore();
    // get connections
    const checkConnection = await this.checkConnection(userRef);

    if (checkConnection.empty) {
      this.reset();
      appStore.setScreenReady(true);
      return;
    }

    const connectionRef = checkConnection.docs[0].ref.parent
      .parent as DocumentReference<Connection>;
    const connectionDoc = await getDoc(connectionRef);

    const connectedUsers = await getDocs(
      collection(firestore, `/connections/${connectionDoc.id}/connected-users`)
    );

    this.selectedConnection = {
      id: connectionDoc.id,
      ref: connectionDoc.ref,
      ...(connectionDoc.data() as Connection),
      connectedUsers: connectedUsers.docs.map((user) => ({
        id: user.id,
        ref: user.ref,
        ...(user.data() as ConnectedUser),
      })),
    };

    // tell them APP IS READY!
    appStore.setScreenReady(true);
  }

  async addConnection(userRef: DocumentReference) {
    if (!authStore.user) return;

    const firestore = getFirestore();
    // add new connection
    const createdAt = Timestamp.now();
    const newConnection = await addDoc<Connection>(
      collection(firestore, "/connections") as CollectionReference<Connection>,
      {
        active: true,
        createdAt,
      }
    );

    const userRefs = [authStore.user.ref, userRef];
    const connectedUsers = await Promise.all(
      userRefs.map((user) =>
        addDoc(
          collection(
            firestore,
            `/connections/${newConnection.id}/connected-users`
          ),
          {
            user,
            active: true,
          }
        )
      )
    );

    // set selectedConnection
    this.selectedConnection = {
      id: newConnection.id,
      ref: newConnection,
      active: true,
      createdAt,
      connectedUsers: connectedUsers.map((connectedUser, index) => ({
        id: connectedUser.id,
        ref: connectedUser,
        user: doc(firestore, `/users/${userRefs[index].id}`),
        active: true,
      })),
    };
  }

  reset() {
    this.selectedConnection = null;
  }
}

export const connectionStore = new ConnectionStore();
