import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { StackScreenProps } from "@react-navigation/stack";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import {
  ScrollView,
  TextInput,
  TouchableOpacity,
} from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import { useQuery } from "react-query";
import { Feather } from "@expo/vector-icons";
import { RootNavigationParamList } from "../../../navigations/main";
import { connectionStore } from "../../../stores/connection";
import { Diary } from "../../../types/diary";
import { Text } from "../../../components/custom";
import { Pressable, StyleSheet, View } from "react-native";
import { colors } from "../../../styles/colors";
import { DiaryPhoto } from "../../../types/photo";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { ImageSwiper } from "../components/image-swiper";
import { DiaryViewActionBottomSheet } from "../components/diary-view-action-bottom-sheet";
import { BackHeader } from "../../../components/navigations/back-header";
import { DiaryDeleteModal } from "../components/diary-delete-modal";
import { diaryWriteStore } from "../stores/diary-write";

const blurhash =
  "|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[";

export const DiaryViewScreen = ({
  navigation,
  route,
}: StackScreenProps<RootNavigationParamList, "DiaryView">) => {
  const firestore = getFirestore();
  const storage = getStorage();
  const { diaryId } = route.params;

  const [comment, setComment] = useState<string>("");
  const [diaryPhotoURLs, setDiaryPhotoURLs] = useState<string[]>([]);

  const [showActionBottomSheet, setShowActionBottomSheet] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { data } = useQuery(["diary", diaryId], () =>
    connectionStore.selectedConnection
      ? getDoc(
          doc(
            firestore,
            `/connections/${connectionStore.selectedConnection.id}/diaries/${diaryId}`
          )
        ).then((doc) => ({ id: doc, ref: doc.ref, ...(doc.data() as Diary) }))
      : null
  );

  const loadPhotos = async () => {
    const diaryRef = doc(
      firestore,
      `/connections/${connectionStore.selectedConnection?.id}/diaries/${diaryId}`
    );
    const snapshot = await getDocs(
      query(
        collection(
          firestore,
          `/connections/${connectionStore.selectedConnection?.id}/photos`
        ),
        where("parent", "==", diaryRef),
        orderBy("order", "asc")
      )
    );
    const urls = await Promise.all(
      snapshot.docs.map((doc) =>
        getDownloadURL(ref(storage, (doc.data() as DiaryPhoto).path))
      )
    );
    setDiaryPhotoURLs(urls);
  };

  const handleDelete = async () => {
    await diaryWriteStore.deleteDiary(diaryId);
    navigation.replace("Root", { screen: "Diary" });
  };

  useEffect(() => {
    if (diaryId) {
      loadPhotos();
    }
  }, [diaryId]);

  return (
    <SafeAreaView style={styles.container}>
      <BackHeader
        navigation={navigation}
        title={data ? format(data.date.toDate(), "yyyy-MM-dd") : ""}
        headerRight={() => (
          <TouchableOpacity
            style={{ padding: 16.0 }}
            onPress={() => setShowActionBottomSheet(true)}
          >
            <Feather name="more-vertical" size={24} color="#333" />
          </TouchableOpacity>
        )}
      />

      <ScrollView contentContainerStyle={{ height: "100%" }}>
        {diaryPhotoURLs.length > 0 ? (
          <ImageSwiper images={diaryPhotoURLs} />
        ) : null}

        <View style={{ padding: 16.0 }}>
          <Text fontSize={20.0} isBold>
            {data?.title}
          </Text>
          <View
            style={{
              borderBottomColor: "#ddd",
              borderBottomWidth: StyleSheet.hairlineWidth,
              marginVertical: 16.0,
            }}
          />
          <Text fontSize={16.0} style={{ marginBottom: 16.0 }}>
            {data?.content}
          </Text>
        </View>
      </ScrollView>
      <View style={styles.commentInputContainer}>
        <TextInput
          style={styles.commentInput}
          placeholder="댓글을 입력하세요."
          placeholderTextColor="#ddd"
          value={comment}
          onChangeText={setComment}
        />
        <Pressable style={styles.commentButton}>
          <Feather name="send" size={24} color={colors.primary} />
        </Pressable>
      </View>

      <DiaryDeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onDelete={handleDelete}
      />
      <DiaryViewActionBottomSheet
        open={showActionBottomSheet}
        setOpen={setShowActionBottomSheet}
        onEdit={() => navigation.replace("DiaryWrite", { diaryId })}
        onDelete={() => {
          setShowActionBottomSheet(false);
          setShowDeleteModal(true);
        }}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  scrollViewContainer: { flex: 1, padding: 16.0 },
  image: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
    width: "100%",
    height: "auto",
    backgroundColor: "#0553",
  },
  commentInputContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopColor: "#ddd",
    borderTopWidth: 1,
    borderStyle: "solid",
  },
  commentInput: {
    width: "100%",
    paddingHorizontal: 16.0,
    paddingVertical: 16.0,
    fontSize: 16.0,
  },
  commentButton: {
    width: 48.0,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
