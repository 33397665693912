import { useRef, useEffect } from "react";
import DraggableFlatList from "react-native-draggable-flatlist";
import {
  View,
  Pressable,
  Image,
  StyleSheet,
  ScrollView,
  Dimensions,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import BottomSheet from "@gorhom/bottom-sheet";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { observer } from "mobx-react";
import { CustomImageAsset } from "../types/image-asset";
import { diaryWriteStore } from "../stores/diary-write";
import { SafeAreaView } from "react-native-safe-area-context";
import { BackdropComponent } from "../../../components/backdrop";

interface ImageSwapBottomSheetProps {
  images: CustomImageAsset[];
  setImages: React.Dispatch<React.SetStateAction<CustomImageAsset[]>>;
}

const { height } = Dimensions.get("window");

export const ImageSwapBottomSheet = observer(
  ({ images, setImages }: ImageSwapBottomSheetProps) => {
    const insets = useSafeAreaInsets();

    // ref
    const bottomSheetRef = useRef<BottomSheet>(null);

    useEffect(() => {
      if (diaryWriteStore.showSwapImageBottomSheet) {
        bottomSheetRef.current?.snapToIndex(0);
      } else {
        bottomSheetRef.current?.close();
      }
    }, [diaryWriteStore.showSwapImageBottomSheet]);

    const handleDeleteImage = (imageToDelete: CustomImageAsset) => {
      setImages(images.filter((image) => image !== imageToDelete));
    };

    return (
      <BottomSheet
        onClose={() => diaryWriteStore.setShowSwapImageBottomSheet(false)}
        ref={bottomSheetRef}
        snapPoints={["90%"]}
        index={-1}
        enableHandlePanningGesture={false}
        enableOverDrag={false}
        enablePanDownToClose={false}
        enableContentPanningGesture={false}
        containerHeight={height + insets.bottom}
        backdropComponent={BackdropComponent}
      >
        <ScrollView style={{ flex: 1, height: "100%" }}>
          <DraggableFlatList
            data={images}
            keyExtractor={(item, index) =>
              item.diaryPhoto
                ? item.diaryPhoto.id
                : item.imageAsset
                ? item.imageAsset.id
                : ""
            }
            onDragEnd={({ data }) => setImages(data)}
            dragItemOverflow
            style={{ paddingHorizontal: 8.0, paddingVertical: 4.0 }}
            renderItem={({ item, drag, isActive }) => (
              <View
                style={[
                  styles.itemContainer,
                  isActive && {
                    transform: [{ scale: 1.05 }],
                  },
                ]}
              >
                <Pressable
                  onLongPress={drag}
                  disabled={isActive}
                  style={[styles.item]}
                >
                  <Feather
                    name="menu"
                    size={24}
                    color="#666"
                    style={{ marginRight: 8.0 }}
                  />
                  <Image
                    key={item.diaryPhoto?.id ?? item.imageAsset?.id}
                    source={{
                      uri: item.diaryPhoto?.url ?? item.imageAsset?.uri,
                    }}
                    style={{ width: 64, height: 64 }}
                  />
                </Pressable>
                <Pressable onPress={() => handleDeleteImage(item)}>
                  <Feather name="x" size={24} color="#666" />
                </Pressable>
              </View>
            )}
          />
        </ScrollView>
      </BottomSheet>
    );
  }
);

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginVertical: 4.0,
    padding: 8.0,
  },
  item: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  contentContainer: {
    flex: 1,
    justifyContent: "center",
  },
});
