import "setimmediate";
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

import { useCallback } from "react";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { NavigationContainer } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { QueryClient, QueryClientProvider } from "react-query";
import { RootSiblingParent } from "react-native-root-siblings";
import { useFonts } from "expo-font";
import { PortalProvider } from "@gorhom/portal";
import { MainNavigation } from "./src/navigations/main";
import { authStore } from "./src/stores/auth";

// Initialize Firebase
import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  indexedDBLocalPersistence,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "./src/static/firebase-config";
import {
  CACHE_SIZE_UNLIMITED,
  enableIndexedDbPersistence,
  initializeFirestore,
} from "firebase/firestore";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});

enableIndexedDbPersistence(firestore).catch((err) => {
  if (err.code == "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code == "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});
// Subsequent queries will use persistence, if it was enabled successfully

const auth = getAuth();
setPersistence(auth, indexedDBLocalPersistence)
  .then((res) => {
    // console.log(res);
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
    // return signInWithEmailAndPassword(auth, email, password);
    // return signInWithPopup(auth, new GoogleAuthProvider());
  })
  .catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error(error);
  });

auth.onAuthStateChanged((user) => {
  authStore.handleLogin(user);
});

const queryClient = new QueryClient();

export default function App() {
  const [fontsLoaded] = useFonts({
    "NotoSans-Black": require("./assets/fonts/NotoSansKR-Black.otf"),
    "NotoSans-Bold": require("./assets/fonts/NotoSansKR-Bold.otf"),
    "NotoSans-Light": require("./assets/fonts/NotoSansKR-Light.otf"),
    "NotoSans-Medium": require("./assets/fonts/NotoSansKR-Medium.otf"),
    "NotoSans-Regular": require("./assets/fonts/NotoSansKR-Regular.otf"),
    "NotoSans-Thin": require("./assets/fonts/NotoSansKR-Thin.otf"),
  });

  const onLayoutRootView = useCallback(async () => {
    // if (fontsLoaded) {
    //   await SplashScreen.hideAsync();
    // }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RootSiblingParent>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <SafeAreaProvider>
            <PortalProvider>
              <NavigationContainer>
                <MainNavigation />
                <StatusBar style="dark" />
              </NavigationContainer>
            </PortalProvider>
          </SafeAreaProvider>
        </GestureHandlerRootView>
      </RootSiblingParent>
    </QueryClientProvider>
  );
}

serviceWorkerRegistration.register();
