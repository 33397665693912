import { Platform } from "react-native";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
import { observer } from "mobx-react";
import { NavigatorScreenParams, useNavigation } from "@react-navigation/native";
import { InviteScreen } from "../screens/invite/invite";
import { connectionStore } from "../stores/connection";
import { DiaryWriteScreen } from "../services/diary/screens/diary-write";
import { DiaryViewScreen } from "../services/diary/screens/diary-view";
import { authStore } from "../stores/auth";
import { LoginScreen } from "../screens/login";
import { Loading } from "../components/loading";
import { appStore } from "../stores/app";
import { TabNavigation, TabNavigationParamList } from "./tab";
import { SplashScreen } from "../components/splashscreen";

export type RootNavigationParamList = {
  Root: NavigatorScreenParams<TabNavigationParamList>;
  DiaryWrite: { diaryId: string | null };
  DiaryView: { diaryId: string };
  Invite: undefined;
  Login: undefined;
};

const Stack = createStackNavigator<RootNavigationParamList>();

export const MainNavigation = observer(() => {
  const navigation = useNavigation();

  return (
    <>
      {authStore.user ? (
        !!connectionStore.selectedConnection ? (
          <Stack.Navigator initialRouteName="Root">
            <Stack.Screen
              name="Root"
              component={TabNavigation}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="DiaryWrite"
              component={DiaryWriteScreen}
              options={{
                headerShown: false,
                ...(Platform.OS === "web"
                  ? {
                      ...TransitionPresets.ModalSlideFromBottomIOS,
                    }
                  : {}),
              }}
            />
            <Stack.Screen
              name="DiaryView"
              component={DiaryViewScreen}
              options={{
                headerShown: false,
                ...(Platform.OS === "web"
                  ? {
                      ...TransitionPresets.ModalSlideFromBottomIOS,
                    }
                  : {}),
                headerTitleStyle: { fontFamily: "NotoSans-Black" },
              }}
            />
          </Stack.Navigator>
        ) : (
          <Stack.Navigator
            screenOptions={{ headerShown: false }}
            initialRouteName="Invite"
          >
            <Stack.Screen name="Invite" component={InviteScreen} />
          </Stack.Navigator>
        )
      ) : (
        <Stack.Navigator
          initialRouteName="Login"
          screenOptions={{
            headerShown: false,
          }}
        >
          <Stack.Screen name="Login" component={LoginScreen} />
        </Stack.Navigator>
      )}
      {appStore.loading ? <Loading /> : null}
      {!appStore.screenReady ? <SplashScreen /> : null}
    </>
  );
});
