import BottomSheet from "@gorhom/bottom-sheet";
import { useEffect, useRef } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Feather } from "@expo/vector-icons";
import { Text } from "../../../components/custom";
import { BackdropComponent } from "../../../components/backdrop";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface DiaryViewActionBottomSheetProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onEdit: () => void;
  onDelete: () => void;
}

export const DiaryViewActionBottomSheet = ({
  open,
  setOpen,
  onEdit,
  onDelete,
}: DiaryViewActionBottomSheetProps) => {
  const insets = useSafeAreaInsets();
  const ref = useRef<BottomSheet>(null);

  useEffect(() => {
    if (open) ref.current?.collapse();
    else ref.current?.close();
  }, [open]);

  return (
    <BottomSheet
      ref={ref}
      index={-1}
      snapPoints={[140 + insets.bottom]}
      backdropComponent={BackdropComponent}
      onClose={() => setOpen(false)}
      enablePanDownToClose
    >
      <View>
        <Pressable
          style={styles.button}
          onPress={() => {
            onEdit();
            setOpen(false);
          }}
        >
          <Feather name="edit-3" size={24} color="#333" />
          <Text style={styles.buttonText}>수정하기</Text>
        </Pressable>
        <Pressable
          style={styles.button}
          onPress={() => {
            onDelete();
            setOpen(false);
          }}
        >
          <Feather name="trash" size={24} color="#333" />
          <Text style={styles.buttonText}>삭제하기</Text>
        </Pressable>
      </View>
    </BottomSheet>
  );
};

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 16.0,
  },
  buttonText: {
    fontSize: 16.0,
    marginLeft: 8.0,
    color: "#333",
  },
});
