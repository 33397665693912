import BottomSheet from "@gorhom/bottom-sheet";
import { Portal } from "@gorhom/portal";
import { getFirestore, updateDoc } from "firebase/firestore";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { BackdropComponent } from "../../../components/backdrop";
import { Button } from "../../../components/custom";
import { TextInput } from "../../../components/custom/text-input";
import { appStore } from "../../../stores/app";
import { bucketListStore } from "../stores/bucket-list";

export const BucketListItemEditBottomSheet = observer(() => {
  const insets = useSafeAreaInsets();
  const ref = useRef<BottomSheet>(null);

  const [title, setTitle] = useState<string>("");

  const handleEdit = async () => {
    if (!bucketListStore.selectedBucketListItem) return;

    try {
      appStore.setLoading(true);
      await updateDoc(bucketListStore.selectedBucketListItem.ref, {
        title,
      });
      await bucketListStore.refetch();
      bucketListStore.showEditBottomSheeet = false;
      bucketListStore.selectedBucketList = null;
    } finally {
      appStore.setLoading(false);
    }
  };

  useEffect(() => {
    if (bucketListStore.showEditBottomSheeet) ref.current?.collapse();
    else ref.current?.close();
  }, [bucketListStore.showEditBottomSheeet]);

  useEffect(() => {
    bucketListStore.selectedBucketListItem &&
      setTitle(bucketListStore.selectedBucketListItem?.title);
  }, [bucketListStore.selectedBucketListItem]);

  return (
    <Portal>
      <BottomSheet
        ref={ref}
        index={-1}
        snapPoints={[164 + insets.bottom]}
        backdropComponent={BackdropComponent}
        enablePanDownToClose
        onClose={() => (bucketListStore.showEditBottomSheeet = false)}
      >
        <View style={{ padding: 16.0 }}>
          <TextInput
            defaultValue={title}
            onChangeText={setTitle}
            style={{ marginBottom: 12.0 }}
            placeholder="함께하고 싶은 것을 알려주세요!"
            onSubmitEditing={handleEdit}
          />
          <Button
            title="수정하기"
            textStyle={{ fontWeight: "bold" }}
            onPress={handleEdit}
          />
        </View>
      </BottomSheet>
    </Portal>
  );
});
