import { Text as RNText } from "react-native";
import { TextProps } from "react-native/types";
import { colors } from "../../styles/colors";

interface CustomTextProps extends TextProps {
  color?: string;
  isBold?: boolean;
  fontSize?: number;
}

export const Text = ({
  style,
  children,
  color = colors.text,
  isBold = false,
  fontSize = 14.0,
  ...props
}: CustomTextProps) => {
  const fontFamily = isBold
    ? "NotoSans-Bold"
    : style
    ? // @ts-ignore
      style.fontWeight === "bold"
      ? "NotoSans-Bold"
      : // @ts-ignore
      style.fontWeight === "800"
      ? "NotoSans-Black"
      : // @ts-ignore
      style.fontWeight === "light"
      ? "NotoSans-Black"
      : "NotoSans-Regular"
    : "NotoSans-Regular";

  return (
    <RNText style={[{ fontFamily, color, fontSize }, style]} {...props}>
      {children}
    </RNText>
  );
};
