import React from "react";
import { format } from "date-fns";
import { StyleSheet, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Feather } from "@expo/vector-icons";
import { Text } from "../custom";
import { colors } from "../../styles/colors";
import {
  StackNavigationOptions,
  StackNavigationProp,
} from "@react-navigation/stack";

interface BackHeaderProps extends Partial<StackNavigationOptions> {
  navigation: StackNavigationProp<any>;
}

export const BackHeader = ({
  navigation,
  title,
  headerRight,
}: BackHeaderProps) => {
  return (
    <View style={styles.headerContainer}>
      <TouchableOpacity
        containerStyle={styles.headerActionGroupButton}
        onPress={() => navigation.goBack()}
      >
        <Feather name="arrow-left" size={24} color="#333" />
      </TouchableOpacity>
      <Text style={styles.titleText}>{title}</Text>
      {headerRight ? headerRight({}) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    height: 64,
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderStyle: "solid",
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
  },
  headerActionGroupButton: {
    padding: 16.0,
    justifyContent: "center",
    alignItems: "center",
  },
  scrollViewContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  titleText: {
    fontSize: 20.0,
    fontWeight: "bold",
    color: "#333",
  },
});
