import { Modal as RNModal, StyleSheet, View } from "react-native";
import { ModalProps } from "react-native";
import {
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";

interface CustomModalProps extends ModalProps {
  onClose: () => void;
}

export const Modal = ({
  style,
  animationType = "fade",
  children,
  onClose,
  onRequestClose,
  ...props
}: CustomModalProps) => {
  return (
    <RNModal
      style={[{ flex: 1 }, style]}
      transparent
      animationType={animationType}
      onRequestClose={() => onClose()}
      {...props}
    >
      <TouchableOpacity
        style={styles.modalBackground}
        activeOpacity={1}
        onPressOut={() => onClose()}
      >
        <TouchableWithoutFeedback style={{ width: "100%" }}>
          <View style={{ width: "100%" }}>{children}</View>
        </TouchableWithoutFeedback>
      </TouchableOpacity>
    </RNModal>
  );
};

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 16.0,
  },
});
