import {
  KeyboardAvoidingView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { authStore } from "../../stores/auth";
import Toast from "react-native-root-toast";
import * as Clipboard from "expo-clipboard";
import { SafeAreaView } from "react-native-safe-area-context";
import { observer } from "mobx-react";
import { Feather } from "@expo/vector-icons";
import { Image } from "expo-image";
import { Text } from "../../components/custom";
import { colors } from "../../styles/colors";
import { connectionStore } from "../../stores/connection";
import { getAuth, signOut } from "firebase/auth";
import { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import { RootNavigationParamList } from "../../navigations/main";

export const InviteScreen = observer(
  ({
    navigation,
  }: BottomTabScreenProps<RootNavigationParamList, "DiaryView">) => {
    const firestore = getFirestore();

    const [invitationCode, setInvitationCode] = useState<string>("");

    useEffect(() => {
      if (
        !!connectionStore.selectedConnection &&
        connectionStore.selectedConnection.connectedUsers.length > 2
      ) {
        Toast.show("연인과 연결되었어요!");
      }
    }, [connectionStore.selectedConnection]);

    const handleCopy = async () => {
      await Clipboard.setStringAsync(authStore.user?.id ?? "");
      Toast.show("초대코드가 복사되었어요.");
    };

    const handleConnection = async () => {
      if (!authStore.user) {
        Toast.show("로그인 해주세요.");
        return;
      }
      if (invitationCode.length === 0) {
        Toast.show("초대코드를 입력해주세요.");
        return;
      }
      // check if invitation is invalid
      const invitedUserDoc = await getDoc(
        doc(firestore, `/users/${invitationCode}`)
      );
      if (!invitedUserDoc.exists()) {
        Toast.show("존재하지 않는 코드에요. 초대코드를 다시 확인해주세요.");
        return;
      }
      if (invitationCode === authStore.user?.id) {
        Toast.show(
          "자신과는 이미 연인 사이에요 :) 다른 사람의 초대코드를 입력해주세요."
        );
        return;
      }
      // check if user can create new connection
      // check connection exist
      // TODO. 연결이 있는데 혼자일 때 대응
      if (!(await connectionStore.checkConnection(authStore.user.ref)).empty) {
        Toast.show("이미 연결된 상대가 있어요!");
        return;
      }

      // 연결이 아예 처음일 때
      await connectionStore.addConnection(
        doc(firestore, `/users/${invitationCode}`)
      );
    };

    return (
      <KeyboardAvoidingView style={{ width: "100%", flex: 1 }}>
        <SafeAreaView style={styles.container}>
          <View
            style={{
              width: "100%",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              style={styles.image}
              source={require("../../assets/diary.png")}
            />
            <Text style={styles.title}>
              연인과 연결하고,{" "}
              <Text style={{ color: colors.primary }} fontSize={20.0} isBold>
                추억
              </Text>
              을 공유하세요!
            </Text>

            <View style={{ width: "100%", marginBottom: 16.0 }}>
              <Text style={styles.subtitle}>나의 초대코드</Text>

              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextInput
                  style={[
                    styles.input,
                    { marginRight: 8.0, backgroundColor: "#ddd" },
                  ]}
                  value={authStore.user?.id}
                />
                <TouchableOpacity
                  style={styles.copyButton}
                  onPress={handleCopy}
                >
                  <Feather name="copy" size={20} color="#666" />
                </TouchableOpacity>
              </View>
            </View>

            <View style={{ width: "100%", marginBottom: 16.0 }}>
              <Text style={styles.subtitle}>연인의 초대코드 입력</Text>
              <TextInput
                style={styles.input}
                value={invitationCode}
                onChangeText={setInvitationCode}
              />
            </View>

            <TouchableOpacity
              onPress={() => {
                authStore
                  .handleSignOut()
                  .then(() => navigation.navigate("Login"));
              }}
            >
              <Text>다른 계정으로 로그인</Text>
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            style={[
              styles.connectionButton,
              invitationCode.length === 0
                ? {
                    backgroundColor: "#ccc",
                  }
                : {},
            ]}
            onPress={handleConnection}
            disabled={invitationCode.length === 0}
          >
            <Text style={styles.connectionButtonText}>연결하기</Text>
          </TouchableOpacity>
        </SafeAreaView>
      </KeyboardAvoidingView>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16.0,
  },
  image: {
    width: 130,
    height: 130,
    marginBottom: 24.0,
  },
  title: {
    fontSize: 20.0,
    fontWeight: "bold",
    marginBottom: 24.0,
  },
  subtitle: {
    fontSize: 14.0,
    marginBottom: 8.0,
  },
  input: {
    width: "100%",
    paddingVertical: 12.0,
    paddingHorizontal: 16.0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#666",
    borderRadius: 8.0,
    fontSize: 16.0,
  },
  copyButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: 8.0,
    width: 45,
    height: 45,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#666",
    borderRadius: 8.0,
  },
  connectionButton: {
    paddingVertical: 12.0,
    paddingHorizontal: 16.0,
    backgroundColor: "#f2808a",
    borderRadius: 8.0,
    width: "100%",
    alignItems: "center",
  },
  connectionButtonText: {
    color: "#ffffff",
    fontSize: 16.0,
    fontWeight: "bold",
  },
});
