import {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
} from "@gorhom/bottom-sheet";
import { Dimensions } from "react-native";

const { height } = Dimensions.get("window");

export const BackdropComponent = (props: BottomSheetBackdropProps) => {
  return (
    <BottomSheetBackdrop
      {...props}
      disappearsOnIndex={-1}
      pressBehavior="close"
      opacity={1.5}
      style={[props.style, { height }]}
    />
  );
};
