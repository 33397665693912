import { StyleSheet, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { colors } from "../styles/colors";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import { useEffect } from "react";

export const Loading = () => {
  const scale = useSharedValue(1.0);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: scale.value,
        },
      ],
    };
  });

  useEffect(() => {
    scale.value = withRepeat(
      withTiming(1.5, { duration: 1000 }),
      Infinity,
      true
    );
  }, []);

  return (
    <View style={styles.container}>
      <Animated.View style={animatedStyles}>
        <AntDesign name="heart" size={36} color={colors.primary} />
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },
});
