import { useEffect, useRef } from "react";
import BottomSheet from "@gorhom/bottom-sheet";
import { Pressable, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Portal } from "@gorhom/portal";
import { Feather } from "@expo/vector-icons";
import { observer } from "mobx-react";
import { Text } from "../../../components/custom";
import { BackdropComponent } from "../../../components/backdrop";
import { colors } from "../../../styles/colors";
import { bucketListStore } from "../stores/bucket-list";
import { appStore } from "../../../stores/app";
import { deleteDoc } from "firebase/firestore";

export const BucketListItemActionBottomSheet = observer(() => {
  const insets = useSafeAreaInsets();
  const ref = useRef<BottomSheet>(null);

  useEffect(() => {
    if (bucketListStore.showActionBottomSheet) ref.current?.collapse();
    else ref.current?.close();
  }, [bucketListStore.showActionBottomSheet]);

  const handleEdit = () => {
    bucketListStore.showEditBottomSheeet = true;
    bucketListStore.showActionBottomSheet = false;
  };

  const handleDelete = async () => {
    if (!bucketListStore.selectedBucketListItem) return;
    try {
      appStore.setLoading(true);
      await deleteDoc(bucketListStore.selectedBucketListItem.ref);
      await bucketListStore.refetch();
      // Reset state
      bucketListStore.selectedBucketListItem = null;
      bucketListStore.showActionBottomSheet = false;
    } finally {
      appStore.setLoading(false);
    }
  };

  return (
    <Portal>
      <BottomSheet
        ref={ref}
        index={-1}
        snapPoints={[140 + insets.bottom]}
        backdropComponent={BackdropComponent}
        onClose={() => (bucketListStore.showActionBottomSheet = false)}
        enablePanDownToClose
      >
        <Pressable style={styles.buttonPressable} onPress={handleEdit}>
          <View style={styles.button}>
            <Feather
              name="edit-3"
              size={24}
              color={colors.text}
              style={{ marginRight: 8.0 }}
            />
            <Text color={colors.text}>수정하기</Text>
          </View>
        </Pressable>
        <Pressable style={styles.buttonPressable} onPress={handleDelete}>
          <View style={styles.button}>
            <Feather
              name="trash-2"
              size={24}
              color={colors.danger}
              style={{ marginRight: 8.0 }}
            />
            <Text color={colors.danger}>삭제하기</Text>
          </View>
        </Pressable>
      </BottomSheet>
    </Portal>
  );
});

const styles = StyleSheet.create({
  buttonPressable: {},
  button: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 16.0,
    paddingHorizontal: 16.0,
  },
});
