import { makeAutoObservable } from "mobx";
import { BucketListItemWithId, BucketListWithId } from "../types/bucket-list";

class BucketListStore {
  selectedBucketList: BucketListWithId | null = null;
  selectedBucketListItem: BucketListItemWithId | null = null;

  showActionBottomSheet: boolean = false;
  showEditBottomSheeet: boolean = false;

  refetch: any | null = null;

  constructor() {
    makeAutoObservable(this);
  }
}

export const bucketListStore = new BucketListStore();
