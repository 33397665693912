import { useCallback, useEffect, useState } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import {
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import {
  collection,
  DocumentReference,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { FlatList } from "react-native-gesture-handler";
import { format } from "date-fns";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { Image } from "expo-image";
import { RootNavigationParamList } from "../../../navigations/main";
import { Text } from "../../../components/custom";
import { connectionStore } from "../../../stores/connection";
import { Diary, DiaryWithId } from "../../../types/diary";
import { DiaryPhoto } from "../../../types/photo";
import { colors } from "../../../styles/colors";
import { useQuery } from "react-query";

export const DiaryHomeScreen = ({
  navigation,
}: NativeStackScreenProps<RootNavigationParamList>) => {
  const firestore = getFirestore();

  const { data: diaries, refetch } = useQuery("diaries", () =>
    getDocs(
      query(
        collection(
          firestore,
          `/connections/${connectionStore.selectedConnection?.id}/diaries`
        ),
        orderBy("date", "desc"),
        orderBy("createdAt", "desc")
      )
    ).then((snapshots) =>
      snapshots.docs.map((doc) => ({
        id: doc.id,
        ref: doc.ref,
        ...(doc.data() as Diary),
      }))
    )
  );

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [])
  );

  return (
    <View style={styles.container}>
      <View style={styles.itemContainer}>
        {diaries ? (
          <FlatList
            data={diaries}
            renderItem={({ item }) => {
              const { ref, ...rest } = item;
              return <DiaryItem diaryRef={ref} {...rest} />;
            }}
            numColumns={2}
            keyExtractor={(item) => item.id}
          />
        ) : null}
      </View>
    </View>
  );
};

interface DiaryItemProps extends Diary {
  id: string;
  diaryRef: DocumentReference;
}

const DiaryItem = ({ id, diaryRef, title, date }: DiaryItemProps) => {
  const firestore = getFirestore();
  const storage = getStorage();

  const navigation =
    useNavigation<NativeStackNavigationProp<RootNavigationParamList>>();

  // const {data} = useQuery(["diaries", id, "thumbnail"], () => )

  const [thumbnail, setThumbnail] = useState<string | null>(null);

  useFocusEffect(
    useCallback(() => {
      getDocs(
        query(
          collection(
            firestore,
            `/connections/${connectionStore.selectedConnection?.id}/photos`
          ),
          where("parent", "==", diaryRef),
          orderBy("order", "asc"),
          limit(1)
        )
      ).then((snapshot) => {
        if (!snapshot.empty) {
          getDownloadURL(
            ref(storage, (snapshot.docs[0].data() as DiaryPhoto).path)
          ).then((url) => setThumbnail(url));
        }
      });
    }, [])
  );

  return (
    <TouchableOpacity
      style={{
        flex: 1,
        width: "100%",
        height: 150,
        backgroundColor: thumbnail ? "#000" : "#ddd",
        justifyContent: "center",
        alignItems: "center",
        margin: 8.0,
        borderRadius: 8.0,
        overflow: "hidden",
      }}
      activeOpacity={0.5}
      onPress={() => navigation.navigate("DiaryView", { diaryId: id })}
    >
      {thumbnail ? (
        <Image
          source={thumbnail}
          style={{ width: "100%", height: "100%", opacity: 0.5 }}
        />
      ) : null}
      <View
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text color={thumbnail ? "white" : colors.text}>
          {format(date.toDate(), "yyyy-MM-dd")}
        </Text>
        <Text
          style={{ fontSize: 16.0 }}
          isBold
          color={thumbnail ? "white" : colors.text}
        >
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: "scroll",
  },
  itemContainer: {
    padding: 8.0,
  },
});
