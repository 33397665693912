import { getAuth, signOut, User as FirebaseUser } from "firebase/auth";
import { makeAutoObservable } from "mobx";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { User, UserWithId } from "../types/user";
import { connectionStore } from "./connection";
import { appStore } from "./app";
import { Platform } from "react-native";

class AuthStore {
  user: UserWithId | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async handleLogin(user: FirebaseUser | null) {
    const firestore = getFirestore();
    if (user) {
      const {
        uid,
        displayName,
        email,
        emailVerified,
        isAnonymous,
        phoneNumber,
        photoURL,
      } = user;

      let userDoc = await getDoc(doc(firestore, `/users/${uid}`));
      if (!userDoc.exists()) {
        // add new user
        await setDoc(doc(firestore, `/users/${uid}`), {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          phoneNumber,
          photoURL,
        });
        userDoc = await getDoc(doc(firestore, `/users/${uid}`));
      }
      this.user = {
        id: userDoc.id,
        ref: userDoc.ref,
        ...(userDoc.data() as User),
      };
      if (userDoc.exists()) {
        await connectionStore.fetchConnection(
          doc(firestore, `/users/${userDoc.id}`)
        );
      }
    } else {
      appStore.setScreenReady(true);
    }
  }

  async handleSignOut() {
    const auth = getAuth();
    await signOut(auth);
    this.user = null;

    if (Platform.OS === "web") {
      window.close();
    } else {
      // TODO. update for IOS and Android
    }
  }
}

export const authStore = new AuthStore();
