import { ViewStyle } from "react-native";
import { StyleProp } from "react-native/types";

interface GlobalStyles {
  flexNone: StyleProp<ViewStyle>;
  flexRow: StyleProp<ViewStyle>;
  itemsCenter: StyleProp<ViewStyle>;
  justifyCenter: StyleProp<ViewStyle>;
  shadow: StyleProp<ViewStyle>;
  [name: string]: StyleProp<ViewStyle>;
}

export const globalStyles: GlobalStyles = {
  flexNone: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
  },
  flexRow: {
    flexDirection: "row",
  },
  itemsCenter: {
    alignItems: "center",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
};
