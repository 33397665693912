import { observer } from "mobx-react";
import { StyleSheet, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { colors } from "../styles/colors";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import { useEffect } from "react";

export const SplashScreen = observer(() => {
  const translateY = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: -1 * translateY.value,
        },
      ],
      marginBottom: 8.0,
    };
  });

  useEffect(() => {
    translateY.value = withRepeat(
      withTiming(8.0, { duration: 1000 }),
      Infinity,
      true
    );
  }, []);

  return (
    <View style={styles.container}>
      <Animated.Image
        source={{
          uri: require("../assets/logo.png"),
          width: 100,
          height: 100,
        }}
        style={animatedStyles}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },
});
