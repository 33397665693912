import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { useFocusEffect } from "@react-navigation/native";
import {
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import {
  getFirestore,
  getDocs,
  query,
  collection,
  orderBy,
  DocumentReference,
} from "firebase/firestore";
import { useCallback, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { useQuery } from "react-query";
import { Text } from "../../../components/custom";
import { RootNavigationParamList } from "../../../navigations/main";
import { Feather } from "@expo/vector-icons";
import { connectionStore } from "../../../stores/connection";
import { BucketList, BucketListWithId } from "../types/bucket-list";
import { CreatNewBucketListBottomSheet } from "../bottom-sheets/create-new-bucket-list-bottom-sheet";
import { globalStyles } from "../../../styles/global";
import { BucketListNavigationParamList } from "../navigations/bucket-list";

export const BucketListHomeScreen = ({
  navigation,
}: NativeStackScreenProps<BucketListNavigationParamList>) => {
  const [showCreateBottomSheet, setShowCreateBottomSheet] =
    useState<boolean>(false);

  navigation.setOptions({
    headerRight: () => (
      <Pressable
        style={{ padding: 16.0 }}
        onPress={() => setShowCreateBottomSheet(true)}
      >
        <Feather name="plus" size={24} color="#333" />
      </Pressable>
    ),
  });

  const firestore = getFirestore();

  const { data: bucketLists, refetch } = useQuery("bucket-lists", () =>
    getDocs(
      query(
        collection(
          firestore,
          `/connections/${connectionStore.selectedConnection?.id}/bucket-lists`
        ),
        orderBy("createdAt", "desc")
      )
    ).then((snapshots) =>
      snapshots.docs.map((doc) => ({
        id: doc.id,
        ref: doc.ref as DocumentReference<BucketList>,
        ...(doc.data() as BucketList),
      }))
    )
  );

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [])
  );

  return (
    <View>
      {bucketLists ? (
        <FlatList
          data={bucketLists}
          style={{ padding: 16.0 }}
          renderItem={({ item }) => (
            <BucketListItem navigation={navigation} bucketList={item} />
          )}
          keyExtractor={(item) => item.id}
        />
      ) : null}

      <CreatNewBucketListBottomSheet
        open={showCreateBottomSheet}
        setOpen={setShowCreateBottomSheet}
        onCreate={() => refetch()}
      />
    </View>
  );
};

interface BucketListItemProps {
  navigation: NativeStackNavigationProp<BucketListNavigationParamList>;
  bucketList: BucketListWithId;
}

const BucketListItem = ({ navigation, bucketList }: BucketListItemProps) => {
  return (
    <Pressable
      onPress={() =>
        navigation.navigate("BucketListView", { bucketListId: bucketList.id })
      }
      style={[itemStyles.container, globalStyles.shadow]}
    >
      <Text fontSize={16.0}>{bucketList.title}</Text>
    </Pressable>
  );
};

const itemStyles = StyleSheet.create({
  container: {
    marginBottom: 16.0,
    backgroundColor: "white",
    borderStyle: "solid",
    borderRadius: 8.0,
    padding: 16.0,
  },
});
